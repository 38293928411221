import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import LoginBg from "../assets/usericonlogin.png";
import { transport } from "../services/Network";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

const emailRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const passRef = useRef<HTMLInputElement>(null);
  const confirmPassRef = useRef<HTMLInputElement>(null);
  const [trainer, setTrainer] = useState("");
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [messageToShow, setMessageToShow] = useState("");
  const [loading, setLoading] = useState(false);
  const getDetails = async () => {
    if (!params.get("email")) {
      toast.error("Trainee is not invited yet!");
      return;
    }

    setLoading(true);
    const res = await transport(
      "/trainee/invite?email=" + params.get("email"),
      {
        method: "GET",
      }
    );
    if (res?.status !== 200) {
      setMessageToShow(res?.data?.message ?? "Trainee is not invited yet!");
      toast.error(res?.data?.message ?? "Trainee is not invited yet!");
    } else {
      setEmail(res?.data?.details?.email);
      setName(res?.data?.details?.fullname);
      setTrainer(res?.data?.details?.trainer_name);
    }
    setLoading(false);
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Layout showSidebar={false}>
      <div className="w-screen bg-[#E6E6E6] h-screen max-h-screen -ml-4 md:-ml-8 -mt-10">
        <div className="relative pt-20 z-10 -top-20 h-screen max-h-screen">
          <img
            src={LoginBg}
            className="-z-10 absolute md:-top-12 lg:-top-44 md:-left-28 lg:-left-40"
            alt="user login bg"
          />
          <div className="w-full h-full pb-4 overflow-y-auto flex flex-col items-center">
            <h1 className="pt-12 pb-4 md:pb-6 text-3xl md:text-5xl text-center font-bold">
              Welcome to Avatar
            </h1>
            <div className="bg-white relative bg-opacity-85 w-11/12 md:w-5/6 lg:w-1/2 2xl:w-[45%] rounded-md px-6 md:px-12 lg:px-16 xl:px-24 2xl:px-36 py-6 md:py-12">
              <h2
                className={`w-full text-center text-3xl font-bold pt-4 ${
                  messageToShow !== "" || loading ? "opacity-0" : ""
                }`}
              >
                Invited by {trainer ? trainer : "Trainer"}
              </h2>
              <form
                className={`w-full pt-3.5 ${
                  messageToShow !== "" || loading ? "opacity-0" : ""
                } lg:pt-7`}
                onSubmit={async (ev) => {
                  ev.preventDefault();
                  if(!emailRegex.test(passRef.current?.value??"")){
                    toast.error("Password must be 8-32 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character.")
                    return
                  }
                  if (
                    passRef.current?.value !== confirmPassRef.current?.value
                  ) {
                    toast.error("Confirm password and password must be same");
                    return;
                  }
                  const res = await transport({
                    url: "/trainee/",
                    data: {
                      email: email,
                      password: passRef.current?.value,
                    },
                    method: "POST",
                  });
                  if (res?.status === 201) {
                    toast.success("Registered successfully");
                    navigate("/login", { replace: true });
                  } else {
                    toast.error(res.data.message);
                  }
                }}
              >
                <div className="w-full">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    disabled
                    placeholder="Enter Your Email"
                    className="border-2 w-full border-gray-400 px-4 py-2 lg:py-3.5 text-gray-400 block rounded-md"
                  />
                </div>
                <div className="w-full">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    id="email"
                    value={email}
                    type="email"
                    disabled
                    placeholder="Enter Your Email"
                    className="border-2 w-full border-gray-400 px-4 py-2 lg:py-3.5 text-gray-400 block rounded-md"
                  />
                </div>

                <div className="w-full relative">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    name="password"
                    id="password"
                    ref={passRef}
                    type={showPass ? "text" : "password"}
                    required
                    placeholder="Enter Your Password"
                    className="border-2 w-full border-gray-400  pl-4 pr-12 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                  <Icon
                    className="text-2xl absolute text-[#28A9E2] right-3.5 top-1/2 translate-y-1/2 cursor-pointer"
                    icon={showPass ? "mdi:eye-off" : "mdi:eye"}
                    onClick={() => setShowPass((prev) => !prev)}
                  />
                </div>
                <div
                  className={`w-full ${
                    messageToShow !== "" || loading ? "hidden" : ""
                  } relative`}
                >
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="password"
                  >
                    Confirm Password
                  </label>
                  <input
                    name="confirm-password"
                    id="confirm-password"
                    ref={confirmPassRef}
                    type={showConfirmPass ? "text" : "password"}
                    required
                    placeholder="Enter Your Password"
                    className="border-2 w-full border-gray-400  pl-4 pr-12 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                  <Icon
                    className="text-2xl absolute text-[#28A9E2] right-3.5 top-1/2 translate-y-1/2 cursor-pointer"
                    icon={showConfirmPass ? "mdi:eye-off" : "mdi:eye"}
                    onClick={() => setShowConfirmPass((prev) => !prev)}
                  />
                </div>
                <button
                  className={`w-full my-5 max-lg:py-2.5 p-4 bg-[#0168B5] text-white font-semibold text-lg rounded-md text-center ${
                    messageToShow !== "" || loading ? "hidden" : ""
                  }`}
                  type="submit"
                >
                  Sign Up
                </button>
              </form>
              <p
                className={`text-center w-full pt-4 lg:pt-10 ${
                  messageToShow !== "" ? "opacity-0" : ""
                } px-4 font-medium text-[#096DB6]`}
              >
                Forgot your password?
              </p>
              {(messageToShow != "" || loading) && (
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 whitespace-nowrap md:text-2xl italic font-medium -translate-y-1/2 z-40">
                  {loading ? <CircularProgress /> : messageToShow}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
