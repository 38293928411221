import Layout from "../components/Layout";
import userIcon from "../assets/user.png";
import TableTopBar from "../components/TableTopBar";
import Table from "../components/Table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../services/states/store";
import { transport } from "../services/Network";
const Leaderboard = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const selectedGroup: string = useSelector(
    (state: RootState) => state.selectedGroup
  );
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState("");
  const selectedOption: any[] = useSelector(
    (state: RootState) => state.sortTableData
  );
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  useEffect(() => {
    if (
      selectedGroup &&
      availableGroups.findIndex(
        (grp) => grp.id == selectedGroup && grp.status !== "completed"
      ) > -1
    ) {
      transport("/scores/latest_scores?group_id=" + selectedGroup).then(
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            const data = [
              ...res.data.data.sort((a: any, b: any) => {
                const val = b.score - a.score;
                return val === 0
                  ? a.trainee_fullname.localeCompare(b.trainee_fullname)
                  : val;
              }),
            ];
            setData(
              data.reduce((acc: any[], val: any) => {
                if (acc.length == 0) {
                  acc.push({
                    name: val.trainee_fullname,
                    points: val.score,
                    rank: 1,
                  });
                } else {
                  if (acc.at(-1).points > val.score) {
                    acc.push({
                      name: val.trainee_fullname,
                      points: val.score,
                      rank: acc.at(-1).rank + 1,
                    });
                  } else {
                    acc.push({
                      name: val.trainee_fullname,
                      points: val.score,
                      rank: acc.at(-1).rank,
                    });
                  }
                }
                return acc;
              }, [])
            );
          } else {
            setData([]);
          }
        }
      );
    } else {
      setData([]);
    }
  }, [selectedGroup]);
  useEffect(() => {
    if (searchInput.length > 0) {
      const searchedData = [...data];
      setFilteredData(
        searchedData
          .filter((val) =>
            val.name.toLowerCase().includes(searchInput.toLowerCase())
          )
          .sort((a: any, b: any) =>
            selectedOption[2] === "desc"
              ? `${b[selectedOption[1]]}`.localeCompare(
                  `${a[selectedOption[1]]}`
                )
              : `${a[selectedOption[1]]}`?.localeCompare(
                  `${b[selectedOption[1]]}`
                )
          )
      );
    } else {
      setFilteredData(
        data.sort((a: any, b: any) =>
          selectedOption[2] === "desc"
            ? `${b[selectedOption[1]]}`.localeCompare(`${a[selectedOption[1]]}`)
            : `${a[selectedOption[1]]}`?.localeCompare(
                `${b[selectedOption[1]]}`
              )
        )
      );
    }
  }, [searchInput, data, selectedOption]);

  return (
    <Layout>
      <div className="flex w-full pb-2 items-center gap-4">
        <img src={userIcon} alt="user" className="max-md:h-10" />
        <span className="text-3xl lg:text-4xl">Leaderboard</span>
      </div>
      <TableTopBar
        showAction={false}
        setAction={() => {}}
        pageName="leaderboard"
        updateSearchValue={setSearchInput}
        currentPage={currentPage}
        showSelectGroup={true}
        setCurrentPage={setCurrentPage}
        checkedItems={[]}
        total={filteredData.length}
        totalCount={Math.ceil(filteredData ? filteredData.length / 8 : 0)}
        type={1}
      />
      {filteredData?.length > 0 ? (
        <Table
          headers={["Name", "Points", "Rank"]}
          headerCodes={["name", "points", "rank"]}
          data={filteredData
            ?.slice(currentPage * 8, currentPage * 8 + 8)
            .map((val, idx) => [
              idx,
              val["name"],
              val["points"] ?? 0,
              val["rank"],
            ])}
          checkedItems={[]}
          updateChecked={undefined}
          type="leaderboard"
        />
      ) : (
        <div className="text-center text-gray-500 italic p-8">
          No stats found
          {/* {searchInput.length > 0 ? " with given searched value" : ""} */}
        </div>
      )}
    </Layout>
  );
};

export default Leaderboard;
