const DataCard = (props: { title: string; text: string }) => {
  return (
    <div className="flex flex-col items-center justify-center min-w-36 w-56 min-h-36 px-2.5 max-sm:flex-1 bg-white gap-y-3 py-5 shadow-cardshadow rounded-xl">
      <div className="text-6xl text-bgprimary font-bold text-center">
        {props.title}
      </div>
      <div className="text-base text-black text-center">{props.text}</div>
    </div>
  );
};

export default DataCard;
