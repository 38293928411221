import { Icon } from "@iconify/react";
import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../services/states/store";
import actions from "../../services/states/actions";
import { transport } from "../../services/Network";
import toast from "react-hot-toast";
import SelectComp from "../SelectComp";

const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const AddTraineeModal = (props: { show: boolean; close: () => void }) => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableGroups.length === 0) {
      dispatch(actions.getGroups());
    }
  }, []);
  const onClose = () => {
    setSelectedGroup([]);
    if (nameRef.current) {
      nameRef.current.value = "";
    }
    if (emailRef.current) {
      emailRef.current.value = "";
    }
    props.close();
  };
  return props.show ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Add New Trainees</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 pt-6 pb-5">
          <div>
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              placeholder="Name"
              ref={nameRef}
              name="name"
              required
              id="name"
              className="bg-bgsecondary w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <div>
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              placeholder="Enter Email Address"
              name="email"
              ref={emailRef}
              required
              id="email"
              className="bg-bgsecondary w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <SelectComp
            label="Add to Group"
            options={availableGroups.map((val) => {
              return {
                label: val.name,
                value: val.id,
              };
            })}
            selectedValues={selectedGroup}
            placeholder="Select group to add"
            setSelectedValues={setSelectedGroup}
            order={false}
          />
          <div className="h-5"></div>
          <div className="flex justify-between items-center">
            <button
              onClick={async () => {
                if (nameRef.current?.value && emailRef.current?.value) {
                  if (nameRef.current.value.trim().length <= 1) {
                    toast.error("Name must be greater than 1 character");
                    return;
                  }
                  if (emailRef.current.value.trim().length === 0) {
                    toast.error("Email must not be blank");
                    return;
                  }
                  if (!emailRef.current.value.match(emailRegex)) {
                    toast.error("Email format is invalid");
                    return;
                  }

                  const res = await transport("/trainee/invite", {
                    method: "POST",
                    data: {
                      fullname: nameRef.current.value.trim(),
                      email: emailRef.current.value,
                      groups: selectedGroup.reduce((acc, curr) => {
                        acc.push(curr.value);
                        return acc;
                      }, []),
                    },
                  });
                  if (res?.status === 200) {
                    toast.success(res.data.message);
                    dispatch(actions.getUsers());
                    onClose();
                  } else {
                    toast.error(res.data.message);
                  }
                } else {
                  toast.error("Name and Email of trainee are required");
                }
              }}
              className="text-white flex-1 max-w-40 bg-primary rounded py-2.5 px-5 text-base"
            >
              Save Trainee
            </button>
            <span className="text-xl text-gray-500">or</span>
            <button
              onClick={onClose}
              className="bg-gray-200 flex-1 max-w-40 text-gray-600 rounded py-2.5 px-5 text-base"
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="bg-bgsecondary px-8 py-2 text-sm] text-gray-600">
          The Trainee will receive an email Invitation to create an Account on
          the Avatar platform
        </div>
      </div>
    </div>
  ) : null;
};

export default AddTraineeModal;
