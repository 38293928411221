import React from "react";
import Layout from "../components/Layout";

const About = () => {
  return (
    <Layout showSidebar={false}>
      <div className="w-full min-h-[calc(100vh-9.5rem)] text-gray-700 flex flex-col gap-5 items-stretch bg-white p-4 md:p-8 rounded-md">
        <section>
          <h1 className="text-5xl font-bold mb-4">About Avatar</h1>
          <p className="text-lg mb-6">
            Welcome to DataH2O’s Avatar product, the pinnacle of advanced
            training solutions for contact centre agents. Our revolutionary
            application is designed to simulate real-life customer interactions
            with unparalleled realism, empowering agents to excel in their roles
            with confidence and competence.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">What is Avatar?</h2>
          <p className="mb-6">
            Avatar is a cutting-edge training tool that leverages generative AI
            technology to create lifelike conversational scenarios. Whether you
            are a new hire or an experienced agent, Avatar provides a dynamic
            and immersive training environment that mimics genuine customer
            interactions. This innovative approach ensures that agents are
            well-prepared to handle any situation with ease and professionalism.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Key Features</h2>
          <ul className="list-disc pl-6 mb-6">
            <li className="mb-2">
              <strong>Realistic Simulations:</strong> Avatar engages trainees in
              authentic conversations that reflect real-world dynamics. By
              simulating a wide range of customer personas and scenarios, Avatar
              helps agents develop practical skills that translate seamlessly to
              their everyday tasks. Each interaction is crafted to be as
              realistic as possible, preparing agents for the complexities of
              live customer calls.
            </li>
            <li className="mb-2">
              <strong>Personalised Feedback:</strong> One of the standout
              features of Avatar is its ability to provide immediate,
              individualised feedback. Agents receive direct insights into their
              performance, including scores and detailed assessments on various
              attributes such as accuracy, empathy, friendliness, listening
              skills, and the ability to handle pressure. This feedback loop
              enables continuous improvement without the need for direct manager
              intervention.
            </li>
            <li className="mb-2">
              <strong>Customizable Scenarios:</strong> Avatar is designed to be
              highly adaptable, allowing for the customization of training
              scenarios to meet specific needs. As new products and services are
              introduced, or new issues emerge, Avatar can be updated with new
              personas and scenarios, ensuring that training remains relevant
              and comprehensive.
            </li>
            <li className="mb-2">
              <strong>Data-Driven Insights:</strong> Harness the power of AI
              analytics with Avatar's robust reporting and dashboard features.
              Training teams can access comprehensive performance evaluations,
              identify strengths and areas for improvement, and make informed
              decisions on trainee readiness. These insights help ensure that
              agents are fully prepared to deliver exceptional customer
              experiences.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Benefits of Using Avatar
          </h2>
          <ul className="list-disc pl-6 mb-6">
            <li className="mb-2">
              <strong>Enhanced Training Quality:</strong> Elevate your training
              programs with AI-driven simulations that provide realistic and
              varied customer scenarios.
            </li>
            <li className="mb-2">
              <strong>Boosted Confidence:</strong> Allow agents to build
              confidence in a controlled environment before handling real
              customer interactions.
            </li>
            <li className="mb-2">
              <strong>Accurate Performance Metrics:</strong> Leverage precise
              performance assessments to identify areas for improvement and
              track progress.
            </li>
            <li className="mb-2">
              <strong>Efficient Training Processes:</strong> Optimise your
              training resources, reducing time and costs while maintaining high
              standards of agent preparation.
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Why Choose Avatar?</h2>
          <p className="mb-6">
            Avatar stands out as the premier solution for contact centre
            training due to its focus on realism, personalization, and
            data-driven insights. Our application not only enhances agent skills
            but also significantly reduces training time and costs, leading to
            faster proficiency and improved overall performance.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">
            Commitment to Excellence
          </h2>
          <p className="mb-6">
            At DataH2O, we are committed to pushing the boundaries of what's
            possible in training technology. Avatar is a testament to our
            dedication to innovation, quality, and customer satisfaction. By
            providing a state-of-the-art training tool, we aim to empower
            contact centres to achieve excellence in every customer interaction.
          </p>
          <p>
            Experience the future of contact centre training with Avatar. Join
            us in our mission to revolutionise training and create a new
            standard of excellence in customer service.
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default About;
