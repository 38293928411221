import React, { ReactElement } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../services/states/store";
import { CircularProgress } from "@mui/material";

const Layout = ({
  children,
  showSidebar,
}: {
  children: string | ReactElement | ReactElement[];
  showSidebar?: boolean;
}) => {
  const printingStatus = useSelector(
    (state: RootState) => state.printingStatus
  );
  return (
    <>
      <div
        className={`w-screen min-w-full min-h-screen bg-bgsecondary h-screen ${
          printingStatus && window.location.pathname.includes("/conversations")
            ? ""
            : "max-h-screen overflow-hidden"
        } max-w-full`}
      >
        <Navbar />
        <div className="flex max-w-full bg-bgsecondary flex-nowrap">
          {showSidebar !== false && <Sidebar />}
          <div
            className={`flex-1 max-w-full ${
              window.location.pathname.includes("/login") ||
              window.location.pathname.includes("/forgot-password") ||
              window.location.pathname.includes("/reset-password")
                ? ""
                : "p-4 pt-5 lg:p-8 lg:pt-10"
            } ${
              window.location.pathname.includes("/register")
                ? "overflow-hidden"
                : "overflow-auto"
            } ${
              printingStatus &&
              window.location.pathname.includes("/conversations")
                ? ""
                : "max-h-[calc(100vh-5rem)]"
            } z-0`}
          >
            {children}
          </div>
        </div>
      </div>
      {printingStatus === 2 && (
        <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-75 flex items-center justify-center">
          <CircularProgress size="60px" />
        </div>
      )}
    </>
  );
};

export default Layout;
