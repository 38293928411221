import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import InteractiveIcon from "../assets/interactiveSession.png";
import PasswordLockIconActive from "../assets/password-01.svg";
import { useDispatch, useSelector } from "react-redux";
import { Persona } from "./TrainingPersonasListing";
import { AppDispatch, RootState } from "../services/states/store";
import actions from "../services/states/actions";
import { SessionCard } from "../components/SessionCard";

const TrainingSessions = () => {
  const personasData: Persona[] = useSelector(
    (state: RootState) => state.personas.personas as Persona[]
  );
  const personasSettings: any[] = useSelector(
    (state: RootState) => state.personas.settings
  );
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const dispatch: AppDispatch = useDispatch();
  const [filteredPersonasData, setFilteredPersonasData] = useState<Persona[]>(
    []
  );
  useEffect(() => {
    if (personasData?.length === 0) {
      dispatch(actions.getPersonas());
    }
  }, []);
  useEffect(() => {
    if (userDetails) {
      const personas: string[] = [].concat(
        ...userDetails.groups
          .filter((group: any) => group?.personas && group.status === "active")
          .map((group: any) => group.personas.split(","))
      );
      setFilteredPersonasData(
        personasData.filter((persona) => personas.includes(persona.persona_id))
      );
    }
  }, [personasData, userDetails]);
  return (
    <Layout>
      <div className="flex w-full pb-2 items-center gap-4">
        <img
          className="h-10 md:w-[47px] md:h-[47px]"
          src={InteractiveIcon}
          alt="interactive"
        />
        <div className="flex-1">
          <div className="text-3xl md:text-4xl">Sessions</div>
          <p className="line-clamp-2 text-sm md:text-base">
            Below are your assigned training assessment sessions
          </p>
        </div>
      </div>
      <div className="mt-4 md:mt-8">
        {/* <div className="flex items-center justify-between py-4">
          <div className="flex items-center gap-4">
            <div className="rounded-full p-2.5 flex items-center justify-center bg-[#E6841F] text-white text-xl">
              <Icon icon="mingcute:power-fill" />
            </div>
            <p className="text-xl">Training session not started (8)</p>
          </div>
          <button className="bg-[#E6841F] text-white px-5 py-1.5 text-center rounded">
            View all
          </button>
        </div> */}
        <div className="flex flex-col items-center gap-y-3 md:gap-y-6">
          {filteredPersonasData.map((persona, index) => (
            <SessionCard
              key={"persona_session_" + index}
              lefticon={PasswordLockIconActive}
              title={`${persona.persona_id}`}
              desc={persona.persona_desc}
              personaSetting={personasSettings.find(
                (val) => val.persona_id === persona.persona_id
              )}
              righticon={
                userDetails?.completed_personas?.includes(persona.persona_id)
                  ? "icon-park-outline:check-one"
                  : userDetails?.failed_personas?.includes(persona.persona_id)
                  ? "fad:redo"
                  : "ph:play-circle"
              }
              righttext={
                userDetails?.completed_personas?.includes(persona.persona_id)
                  ? "Completed"
                  : userDetails?.failed_personas?.includes(persona.persona_id)
                  ? "Try Again"
                  : "Get Started"
              }
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default TrainingSessions;
