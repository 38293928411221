import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import TrainingUsersListing from "./pages/TrainingUsersListing";
import Overview from "./pages/Overview";
import Leaderboard from "./pages/Leaderboard";
import Reports from "./pages/Reports";
import TrainingGroupsListing from "./pages/TrainingGroupsListing";
import TrainingPersonasListing from "./pages/TrainingPersonasListing";
import TrainingAssesmentsListing from "./pages/TrainingAssesmentsListing";
import TrainingSessions from "./pages/TrainingSessions";
import TrainingConverstations from "./pages/TrainingConversations";
import Feedback from "./pages/Feedback";
import { disableCache } from "@iconify/react";
import { Toaster } from "react-hot-toast";
import Register from "./pages/Register";
import MyAccount from "./pages/MyAccount";
import About from "./pages/About";
import Help from "./pages/Help";
import ResetPassword from "./pages/ResetPassoword";
import ForgotPassword from "./pages/ForgotPassword";

disableCache("local");
let loggedin = localStorage.getItem("access_token");
const ProtectedElement = (props: { element: JSX.Element }): JSX.Element => {
  loggedin = localStorage.getItem("access_token");
  return loggedin ? props.element : <Navigate replace to="/login" />;
};
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedElement element={<Navigate replace to="/overview" />} />
          }
        />
        <Route
          path="/login"
          element={loggedin ? <Navigate replace to="/overview" /> : <Login />}
        />
        <Route
          path="/forgot-password"
          element={
            loggedin ? <Navigate replace to="/overview" /> : <ForgotPassword />
          }
        />
        <Route
          path="/reset-password"
          element={
            loggedin ? <Navigate replace to="/overview" /> : <ResetPassword />
          }
        />
        <Route
          path="/overview"
          element={<ProtectedElement element={<Overview />} />}
        />
        {/* <Route
          path="/training"
          element={
            <ProtectedElement
              element={
                role === "trainer" ? (
                  <Navigate replace to="/training/users" />
                ) : (
                  <Navigate replace to="/training/sessions" />
                )
              }
            />
          }
        /> */}
        <Route
          path="/leaderboard"
          element={<ProtectedElement element={<Leaderboard />} />}
        />

        <Route
          path="/feedback"
          element={<ProtectedElement element={<Feedback />} />}
        />
        {/* <Route
          path="/reports"
          element={<ProtectedElement element={<Reports />} />}
        /> */}
        <Route
          path="/training/users"
          element={<ProtectedElement element={<TrainingUsersListing />} />}
        />
        <Route
          path="/training/groups"
          element={<ProtectedElement element={<TrainingGroupsListing />} />}
        />
        <Route
          path="/training/personas"
          element={<ProtectedElement element={<TrainingPersonasListing />} />}
        />
        <Route
          path="/training/assessments"
          element={<ProtectedElement element={<TrainingAssesmentsListing />} />}
        />
        <Route
          path="/training/sessions"
          element={<ProtectedElement element={<TrainingSessions />} />}
        />
        <Route
          path="/training/conversations/"
          element={<ProtectedElement element={<TrainingConverstations />} />}
        />
        <Route
          path="/my-account/"
          element={<ProtectedElement element={<MyAccount />} />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<Help />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
