import moment from "moment";
import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";
export const getTimeAgo = (last_login_date: string | undefined): string => {
  if (!last_login_date) {
    return "No activity yet";
  }
  const diff = moment.utc().diff(moment.utc(last_login_date));
  const diffSeconds = diff / 1000;
  if (diffSeconds < 60) {
    const secs = Math.floor(diffSeconds);
    return `${secs} ${secs === 1 ? "sec" : "secs"} ago`;
  }

  const diffMinutes = diffSeconds / 60;
  if (diffMinutes < 60) {
    const mins = Math.floor(diffMinutes);
    return `${mins} ${mins === 1 ? "min" : "mins"} ago`;
  }

  const diffHours = diffMinutes / 60;
  if (diffHours < 24) {
    const hours = Math.floor(diffHours);
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  }

  const diffDays = diffHours / 24;
  const days = Math.floor(diffDays);
  return `${days} ${days === 1 ? "day" : "days"} ago`;
};

const ViewTraineeModal = (props: { close: () => void; trainee: any }) => {
  return props.trainee ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Trainee Details</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 py-6">
          <div>
            <span className="pb-1 pl-0.5 text-gray-600">Name:</span>
            <b className="px-3 py-1.5">{props.trainee.fullname}</b>
          </div>
          <div>
            <span className="pb-1 pl-0.5 text-gray-600">Email:</span>
            <b className="px-3 py-1.5">{props.trainee.email}</b>
          </div>
          <div>
            <span className="pb-1 pl-0.5 text-gray-600">Score:</span>
            <b className="px-3 py-1.5">{props.trainee.score}</b>
          </div>
          <div>
            <span className="pb-1 pl-0.5 text-gray-600">Last activity:</span>
            <b className="px-3 py-1.5">
              {getTimeAgo(props.trainee.last_login)}
            </b>
          </div>
          <div className="flex items-start">
            <span className="pb-1 pl-0.5 text-gray-600">Groups:</span>
            <span className="pb-1 pl-2.5 inline-flex flex-wrap gap-x-1.5">
              {props.trainee?.groups?.length > 0
                ? props.trainee.groups.map((group: any, index: number) => (
                    <b className="whitespace-nowrap">
                      {group.name +
                        (index !== props?.trainee?.groups?.length - 1
                          ? ","
                          : "")}
                    </b>
                  ))
                : "None"}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ViewTraineeModal;
