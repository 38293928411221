import React, { useEffect } from "react";
import Layout from "../components/Layout";
import TrainerDashboard from "../components/TrainerDashboard";
import TraineeDashboard from "../components/TraineeDashboard";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../services/states/store";
import actions from "../services/states/actions";

const Overview = () => {
  const userDetails = useSelector((state: RootState) => state.userDetails);

  return (
    <Layout>
      <div className="flex w-full pb-2 items-center gap-4">
        <div className="flex-1">
          <div className="text-3xl md:text-4xl">
            Welcome, {userDetails?.fullname ?? "Menu"}
          </div>
          <p className="line-clamp-2 max-md:text-sm">
            Welcome to your AI training dashboard for {process.env.REACT_APP_COMPANY_NAME ?? "ABC Widgets"}. Here you will
            find all your important information.
          </p>
        </div>
      </div>
      {localStorage.getItem("role") === "trainer" ? (
        <TrainerDashboard />
      ) : (
        <TraineeDashboard />
      )}
    </Layout>
  );
};

export default Overview;
