import { Icon } from "@iconify/react";
import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../services/states/store";
import actions from "../../services/states/actions";
import toast from "react-hot-toast";

const AddGroupSelectModal = (props: {
  show: boolean;
  close: () => void;
  onSubmit: (groupId: number) => void;
}) => {
  const [selectedGroup, setSelectedGroup] = useState<any>("");
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableGroups.length === 0) {
      dispatch(actions.getGroups());
    }
  }, []);

  const onClose = () => {
    setSelectedGroup("");
    props.close();
  };

  return props.show ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Add Trainees To Group</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 pt-6 pb-5">
          <div>
            <label className="block pb-1 text-gray-600" htmlFor="groups">
              Add to Group
            </label>
            <div className="relative">
              <select
                name="groups"
                id="groups"
                defaultValue=""
                value={selectedGroup}
                onChange={(ev) => {
                  setSelectedGroup(ev.target.value);
                }}
                className="bg-bgsecondary pr-8 appearance-none w-full border border-gray-400 text-gray-400 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
              >
                <option value="">Select Group</option>
                {availableGroups.length > 0 &&
                  availableGroups.map((group) => (
                    <option key={"trainees_group_" + group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </select>
              <Icon
                className="absolute right-4 text-gray-700 top-1/2 text-xl pointer-events-none -translate-y-2/3 z-50"
                icon="tabler:caret-down-filled"
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <button
              onClick={async () => {
                if (selectedGroup) {
                  props.onSubmit(selectedGroup);
                } else {
                  toast.error("Selecting a group is required");
                }
              }}
              className="text-white flex-1 max-w-40 bg-primary rounded py-2.5 px-5 text-base"
            >
              Add
            </button>
            <span className="text-xl text-gray-500">or</span>
            <button
              onClick={onClose}
              className="bg-gray-200 flex-1 max-w-40 text-gray-600 rounded py-2.5 px-5 text-base"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default AddGroupSelectModal;
