import { Action, configureStore, Store, Tuple } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import reducers from "./reducers";
import { useDispatch } from "react-redux";

export const store: Store = configureStore({
  reducer: reducers,
  middleware: () => new Tuple(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ActionType = Action & { payload: any };
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
