import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Layout from "../components/Layout";
import { transport } from "../services/Network";
import LoginBg from "../assets/usericonlogin.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/overview", { replace: true });
    }
  }, []);

  return (
    <Layout showSidebar={false}>
      <div className="w-full bg-[#E6E6E6] h-full max-h-full">
        <div className="relative pt-20 z-10 -top-20 h-screen max-h-screen overflow-hidden">
          <img
            src={LoginBg}
            className="object-cover -z-10 absolute md:-top-12 lg:-top-44 md:-left-28 lg:-left-40"
            alt="user login bg"
          />
          <div className="w-full h-full overflow-y-auto flex flex-col items-center">
            <h1 className="pt-8 pb-4 md:pt-16 md:pb-6 xl:pt-28 xl:pb-11 text-3xl md:text-5xl font-bold">
              Welcome to Avatar
            </h1>
            <div className="bg-white bg-opacity-85 w-11/12 md:w-5/6 lg:w-1/2 2xl:w-[45%] rounded-md px-6 md:px-12 lg:px-16 xl:px-24 2xl:px-36 py-6 md:py-12">
              <h2 className="w-full text-center text-3xl font-bold pt-4">
                Forgot Password
              </h2>
              <form
                className="w-full pt-3.5 lg:pt-7"
                onSubmit={async (ev) => {
                  ev.preventDefault();
                  const res = await transport({
                    url: "trainee/forgot_password",
                    data: {
                      email: emailRef.current?.value,
                    },
                    method: "POST",
                  });

                  if (res?.status === 200) {
                    toast.success(res?.data?.message);
                    if (emailRef.current) {
                      emailRef.current.value = "";
                    }
                  } else {
                    toast.error(res?.data?.message ?? "Something went wrong");
                  }
                }}
              >
                <div className="w-full">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    id="email"
                    ref={emailRef}
                    type="email"
                    required
                    placeholder="Enter Your Email"
                    className="border-2 w-full border-gray-400 px-4 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                </div>

                <button
                  className="w-full my-5 max-lg:py-2.5 p-4 bg-[#0168B5] text-white font-semibold text-lg rounded-md text-center"
                  type="submit"
                >
                  Forgot Password
                </button>
              </form>
              <p
                className="text-center cursor-pointer w-full pt-4 lg:pt-10 px-4 font-medium text-[#096DB6]"
                onClick={() => navigate("/login")}
              >
                Back to Login?
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
