import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import UserIcon from "../assets/usericonlogin.png";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../services/states/store";
import actions from "../services/states/actions";
import toast from "react-hot-toast";
import { Icon } from "@iconify/react";

const MyAccount = () => {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [profilePic, setProfilePic] = useState<string>("");
  const [inputPic, setInputPic] = useState<File>();
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (localStorage.getItem("access_token") && !userDetails) {
      dispatch(actions.getUserDetails(localStorage.getItem("role") as string));
    }
  }, []);
  useEffect(() => {
    if (userDetails) {
      setEmail(userDetails.email);
      setName(userDetails.fullname);
      setProfilePic(userDetails.profile_pic);
    }
  }, [userDetails]);

  return (
    <Layout showSidebar={false}>
      <div className="w-full min-h-[calc(100vh-9.5rem)] flex flex-col gap-5 items-center bg-white p-4 md:p-8 rounded-md">
        <h1 className="text-3xl md:text-5xl text-black mb-4">My Account</h1>
        <div className="w-fit h-fit relative">
          <img
            src={
              inputPic
                ? URL.createObjectURL(inputPic)
                : profilePic
                ? profilePic
                : UserIcon
            }
            className="max-w-full max-h-full min-w-56 min-h-56 w-56 h-56 text-center object-cover rounded-full border-4 border-bgprimary bg-bgsecondary border-solid"
            alt="profile"
          />
          <label
            className="cursor-pointer absolute bottom-0 right-0"
            htmlFor="profilepic"
          >
            <Icon icon="bxs:edit" className="text-[#E4851F] text-3xl" />
          </label>
          <input
            type="file"
            onChange={(ev) => {
              if (ev.target.files && ev.target.files.length > 0) {
                setInputPic(ev.target.files?.[0]);
              }
            }}
            className="hidden"
            id="profilepic"
            accept="image/png,image/jpeg"
          />
        </div>
        <div className="w-full max-w-3xl flex flex-col items-stretch gap-5">
          <div className="flex gap-x-4 items-center">
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(ev) => {
                setName(ev.target.value);
              }}
              name="name"
              id="name"
              className="bg-bgsecondary flex-1 w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <div className="flex gap-x-4 items-center">
            <label
              className="block pb-1 pl-0.5 pr-1 text-gray-600"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="text"
              placeholder="Email"
              value={email}
              disabled
              onChange={() => {}}
              name="email"
              id="email"
              className="bg-bgsecondary disabled:bg-gray-200 disabled:opacity-60 flex-1 w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
        </div>
        <button
          onClick={() => {
            dispatch(
              actions.updateUserDetails(
                localStorage.getItem("role") as string,
                name,
                inputPic
              )
            ).then((resp: any) => {
              if (resp?.payload?.status === 200) {
                toast.success(resp.payload.data.message);
                dispatch(
                  actions.getUserDetails(localStorage.getItem("role") as string)
                );
              } else {
                toast.error("Updation failed please try again later");
              }
            });
          }}
          className="bg-primary text-white rounded py-2 px-6 flex items-center justify-center"
        >
          Save
        </button>
      </div>
    </Layout>
  );
};

export default MyAccount;
