import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";

const ViewGroupModal = (props: { close: () => void; group: any }) => {
  return props.group ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Group Details</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 py-6">
          <div>
            <span className="pb-1 pl-0.5 text-gray-600">Title:</span>
            <b className="px-3 py-1.5">{props.group.name}</b>
          </div>
          <div>
            <span className="pb-1 pl-0.5 text-gray-600">Status:</span>
            <b className="px-3 py-1.5 capitalize">{props.group.status}</b>
          </div>
          <div className="flex items-start">
            <span className="pb-1 pl-0.5 text-gray-600">Trainees:</span>
            <span className="pb-1 pl-2.5 inline-flex flex-wrap gap-x-1.5">
              {props.group.trainees?.length > 0
                ? props.group.trainees.map((trainee: any, index: number) => (
                    <b className="whitespace-nowrap">
                      {trainee.fullname +
                        (index !== props?.group?.trainees?.length - 1
                          ? ","
                          : "")}
                    </b>
                  ))
                : "None"}
            </span>
          </div>
          <div className="flex">
            <span className="pb-1 pl-0.5 text-gray-600">Personas:</span>
            <span className="pb-1 pl-2.5 inline-flex flex-wrap gap-x-1.5">
              {props.group?.personas?.length > 0
                ? props.group?.personas?.map((p: string, index: number) => (
                    <b className="whitespace-nowrap">
                      {p +
                        (index !== props?.group?.personas?.length - 1
                          ? ","
                          : "")}
                    </b>
                  ))
                : "None"}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ViewGroupModal;
