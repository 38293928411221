import { useEffect, useState } from "react";
import actions from "../services/states/actions";
import { RootState, useAppDispatch } from "../services/states/store";
import { useSelector } from "react-redux";
import { Persona } from "../pages/TrainingPersonasListing";
import DataCard from "./DataCard";
import userIcon from "../assets/user.png";
import { Link } from "react-router-dom";
import Table from "./Table";
import { SessionCard } from "./SessionCard";
import PasswordLockIconActive from "../assets/password-01.svg";
import { Icon } from "@iconify/react";
import { transport } from "../services/Network";
import { Doughnut, Bar } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  DoughnutController,
  Colors,CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
Chart.register(ArcElement, Tooltip, DoughnutController, Colors,CategoryScale, LinearScale,BarElement);
const TraineeDashboard = () => {
  const personasData: Persona[] = useSelector(
    (state: RootState) => state.personas.personas as Persona[]
  );
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const dispatch = useAppDispatch();
  const selectedGroup = useSelector((state: RootState) => state.selectedGroup);
  const [filteredPersonasData, setFilteredPersonasData] = useState<Persona[]>(
    []
  );
  const analyticsData = useSelector((state: RootState) => state.analyticsData);
  const [dataLeaderBoard, setDataLeaderBoard] = useState<any[]>([]);
  useEffect(() => {
    if (personasData?.length === 0) {
      dispatch(actions.getPersonas());
    }
  }, []);
  useEffect(() => {
    if (userDetails) {
      const personas: string[] = [].concat(
        ...userDetails.groups
          .filter((group: any) => group?.personas)
          .map((group: any) => group.personas.split(","))
      );
      setFilteredPersonasData(
        personasData.filter(
          (persona) =>
            personas.includes(persona.persona_id) &&
            (analyticsData?.failed?.includes(persona.persona_id) ||
              analyticsData?.not_attempted?.includes(persona.persona_id))
        )
      );
    }
  }, [personasData, userDetails, analyticsData]);

  useEffect(() => {
    setDataLeaderBoard([]);
    if (
      selectedGroup &&
      userDetails?.groups?.findIndex(
        (grp: any) => grp.id == selectedGroup && grp.status !== "completed"
      ) > -1
    ) {
      transport("/scores/latest_scores?group_id=" + selectedGroup).then(
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            const dataLeaderBoardTemp = [
              ...res.data.data.sort((a: any, b: any) => {
                const val = b.score - a.score;
                return val === 0
                  ? a.trainee_fullname.localeCompare(b.trainee_fullname)
                  : val;
              }),
            ];
            setDataLeaderBoard(
              dataLeaderBoardTemp.reduce((acc: any[], val: any) => {
                if (acc.length == 0) {
                  acc.push({
                    trainee_id: val.trainee_id,
                    name: val.trainee_fullname,
                    points: val.score,
                    rank: 1,
                  });
                } else {
                  if (acc.at(-1).points > val.score) {
                    acc.push({
                      trainee_id: val.trainee_id,
                      name: val.trainee_fullname,
                      points: val.score,
                      rank: acc.at(-1).rank + 1,
                    });
                  } else {
                    acc.push({
                      trainee_id: val.trainee_id,
                      name: val.trainee_fullname,
                      points: val.score,
                      rank: acc.at(-1).rank,
                    });
                  }
                }
                return acc;
              }, [])
            );
          }
        }
      );
    }
    if (selectedGroup) {
      dispatch(actions.getAnalyticsData("trainee", selectedGroup, false));
    } else {
      dispatch(actions.getAnalyticsData("trainee", selectedGroup, true));
    }
  }, [selectedGroup]);

  return (
    <div className="max-w-6xl">
      <div className="w-fit pt-3 pb-5">
        <div className="relative max-md:w-full">
          <select
            name="groups"
            id="groups"
            value={selectedGroup}
            onChange={(ev) => {
              dispatch(actions.selectGroup(ev.target.value));
            }}
            className="bg-white h-10 w-full md:w-60 pr-8 appearance-none border-2 border-gray-400 text-gray-400 rounded-md px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
          >
            <option defaultChecked value="">
              Select Group
            </option>
            {userDetails?.groups?.length > 0 &&
              userDetails?.groups
                ?.filter((grp: any) => grp.status === "active")
                .sort((a: any, b: any) =>
                  a?.name?.toString().localeCompare(b?.name?.toString(), {
                    numeric: true,
                  })
                )
                .map((group: any, index: number) => (
                  <option key={"Select-option-group-" + index} value={group.id}>
                    {group.name}
                  </option>
                ))}
          </select>
          <Icon
            className="absolute right-2 text-gray-700 top-1/2 text-xl pointer-events-none -translate-y-1/2 z-50"
            icon="tabler:caret-down-filled"
          />
        </div>
      </div>
      {selectedGroup ? (
        <>
          <div className="flex gap-8 xl:justify-between flex-wrap">
            {[
              {
                title: dataLeaderBoard.find(
                  (val) => val.trainee_id === userDetails.id
                )
                  ? dataLeaderBoard.find(
                      (val) => val.trainee_id === userDetails.id
                    ).rank
                  : 0,
                text: "Rank",
              },
              {
                title: analyticsData?.score ?? 0,
                text: "Score",
              },
              {
                title: analyticsData?.passed?.length ?? 0,
                text: "Completed",
              },
              {
                title:
                  (analyticsData?.failed?.length ?? 0) +
                  (analyticsData?.not_attempted?.length ?? 0),
                text: "Not Completed",
              },
            ].map((val, index) => (
              <DataCard key={"dashboard_data_card" + index} {...val} />
            ))}
          </div>
          {Object.keys(analyticsData).length > 0 &&
          (analyticsData?.failed?.length > 0 ||
            analyticsData?.passed.length > 0) ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-10 ">
              <div className="max-h-80 h-full mx-auto w-full text-center relative">
                <div className="absolute -translate-x-1/2 text-center -z-10 -translate-y-1/2 left-1/2 top-[calc(50%+16px)]">
                  <h2 className="text-2xl md:text-4xl font-bold">
                    {(analyticsData?.passed?.length ?? 0) +
                      (analyticsData?.failed?.length ?? 0) >
                    0
                      ? (
                          ((analyticsData?.passed?.length ?? 0) * 100) /
                          ((analyticsData?.passed?.length ?? 0) +
                            (analyticsData?.failed?.length ?? 0))
                        ).toFixed(1)
                      : 0}
                    %
                  </h2>
                  <span className="text-xl md:text-xl">Pass Rate</span>
                </div>
                <Doughnut
                className="mx-auto min-h-full"
                  options={{
                    responsive: true,
                    plugins: {
                      tooltip: {
                        enabled: true,
                        backgroundColor: "white",
                        titleColor: "black",
                        bodyColor: "black",
                        padding: 10,
                        callbacks: {
                          label: (context) => {
                            return `${
                              (analyticsData?.passed?.length ?? 0) +
                                (analyticsData?.failed?.length ?? 0) >
                              0
                                ? (
                                    ((context.raw as number) * 100) /
                                    ((analyticsData?.passed?.length ?? 0) +
                                      (analyticsData?.failed?.length ?? 0))
                                  ).toFixed(2)
                                : 0
                            }%`;
                          },
                          title: (context: any) => {
                            return context[0]?.label || "";
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ["Pass", "Fail"],
                    datasets: [
                      {
                        backgroundColor: ["#059bff", "#ff0000"],
                        data: [
                          analyticsData?.passed?.length ?? 0,
                          analyticsData?.failed?.length ?? 0,
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </div>
              <div className="h-full mx-auto w-full relative">
              {Object.keys(analyticsData.analysis).length > 0 ? (
                  <Bar
                  className="w-full min-h-full"
                    options={{
                      responsive:true,
                      indexAxis: "y",
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: analyticsData?.analysis
                        ? Object.keys(analyticsData?.analysis).sort((a, b) => {
                            const val =
                              analyticsData?.analysis[b] -
                              analyticsData?.analysis[a];

                            return val !== 0 ? val : a.localeCompare(b);
                          })
                        : ["unknown"],
                      datasets: [
                        {
                          backgroundColor: "#059bff",
                          data: analyticsData?.analysis
                            ? Object.keys(analyticsData?.analysis)
                                .sort((a, b) => {
                                  const val =
                                    analyticsData?.analysis[b] -
                                    analyticsData?.analysis[a];

                                  return val !== 0 ? val : a.localeCompare(b);
                                })
                                .map((assess) => analyticsData.analysis[assess])
                            : [0],
                        },
                      ],
                    }}
                  />
                ) : (
                  <div className="text-center text-gray-500 italic p-8 pt-16">
                    No analysis found for persona assesments
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500 italic p-8 pt-16">
              No analysis found for persona assesments
            </div>
          )}

          <div className="py-10">
            <div className="flex w-full pb-2 items-center gap-4">
              <img src={userIcon} alt="user" className="max-md:h-10" />
              <span className="text-3xl lg:text-4xl">Leaderboard Top 5</span>
            </div>
            {dataLeaderBoard.length > 0 ? (
              <Table
                headers={["Name", "Points", "Rank"]}
                headerCodes={["name", "points", "rank"]}
                data={dataLeaderBoard
                  .slice(0, 5)
                  .map((val: any, idx) => [
                    idx,
                    val["name"],
                    val["points"] ?? 0,
                    val["rank"],
                  ])}
                checkedItems={[]}
                updateChecked={undefined}
                disableSorting={true}
                type="leaderboard"
              />
            ) : (
              <div className="text-center text-gray-500 italic p-8">
                No stats found
                {/* {searchInput.length > 0 ? " with given searched value" : ""} */}
              </div>
            )}
          </div>
          <div className="pb-10">
            <div className="flex w-full pb-2 items-center gap-4">
              <img src={userIcon} alt="user" className="max-md:h-10" />
              <span className="text-3xl lg:text-4xl">
                Sessions to be completed({filteredPersonasData?.length ?? 0})
              </span>
              <div className="flex-1"></div>
              <Link
                to={"/training/sessions"}
                className="bg-[#E6841F] whitespace-nowrap px-1.5 text-sm md:px-3 py-1 md:py-2 rounded-md text-white"
              >
                View all
              </Link>
            </div>
            <div className="flex flex-col items-center gap-y-3 md:gap-y-6">
              {filteredPersonasData?.slice(0, 5).map((persona, index) => (
                <SessionCard
                  key={"persona_session_" + index}
                  lefticon={PasswordLockIconActive}
                  title={`${persona.persona_id}`}
                  desc={persona.persona_desc}
                  righticon={
                    analyticsData?.failed?.includes(persona.persona_id)
                      ? "fad:redo"
                      : "ph:play-circle"
                  }
                  righttext={
                    analyticsData?.failed?.includes(persona.persona_id)
                      ? "Try Again"
                      : "Get Started"
                  }
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center text-gray-500 italic p-8 h-full">
          Please select a group to see analytics
        </div>
      )}
    </div>
  );
};

export default TraineeDashboard;
