import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import LoginBg from "../assets/usericonlogin.png";
import { transport } from "../services/Network";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";
const Login = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/overview", { replace: true });
    }
  }, []);

  return (
    <Layout showSidebar={false}>
      <div className="w-full bg-[#E6E6E6] h-full max-h-full">
        <div className="relative pt-20 z-10 -top-20 h-screen max-h-screen overflow-hidden">
          <img
            src={LoginBg}
            className="object-cover -z-10 absolute md:-top-12 lg:-top-44 md:-left-28 lg:-left-40"
            alt="user login bg"
          />
          <div className="w-full h-full overflow-y-auto flex flex-col items-center">
            <h1 className="pt-8 pb-4 md:pt-16 md:pb-6 xl:pt-28 xl:pb-11 text-3xl md:text-5xl font-bold">
              Welcome to Avatar
            </h1>
            <div className="bg-white bg-opacity-85 w-11/12 md:w-5/6 lg:w-1/2 2xl:w-[45%] rounded-md px-6 md:px-12 lg:px-16 xl:px-24 2xl:px-36 py-6 md:py-12">
              <h2 className="w-full text-center text-3xl font-bold pt-4">
                Sign In
              </h2>
              <form
                className="w-full pt-3.5 lg:pt-7"
                onSubmit={async (ev) => {
                  ev.preventDefault();
                  const res = await transport({
                    url: "/trainer/login",
                    data: {
                      email: emailRef.current?.value,
                      password: passRef.current?.value,
                    },
                    method: "POST",
                  });
                  if (res?.status === 200) {
                    localStorage.setItem("access_token", res.data.access_token);
                    localStorage.setItem(
                      "refresh_token",
                      res.data.refresh_token
                    );
                    localStorage.setItem("role", res.data.role);
                    navigate("/overview", { replace: true });
                  } else if (res?.status === 404) {
                    const res = await transport({
                      url: "/trainee/login",
                      data: {
                        email: emailRef.current?.value,
                        password: passRef.current?.value,
                      },
                      method: "POST",
                    });
                    if (res?.status === 200) {
                      localStorage.setItem(
                        "access_token",
                        res.data.access_token
                      );
                      localStorage.setItem(
                        "refresh_token",
                        res.data.refresh_token
                      );
                      localStorage.setItem("role", res.data.role);
                      setTimeout(() => {
                        navigate("/overview", { replace: true });
                      }, 500);
                    } else {
                      toast.error(res?.data?.message ?? "Something went wrong");
                    }
                  } else {
                    toast.error(res?.data?.message ?? "Something went wrong");
                  }
                }}
              >
                <div className="w-full">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    id="email"
                    ref={emailRef}
                    type="email"
                    required
                    placeholder="Enter Your Email"
                    className="border-2 w-full border-gray-400 px-4 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                </div>
                <div className="w-full relative">
                  <label
                    className="block py-2.5 text-gray-700 font-medium"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    name="password"
                    id="password"
                    ref={passRef}
                    type={showPass ? "text" : "password"}
                    required
                    placeholder="Enter Your Password"
                    className="border-2 w-full border-gray-400 pl-4 pr-12 py-2 lg:py-3.5 placeholder:text-gray-400 text-black block rounded-md"
                  />
                  <Icon
                    className="text-2xl absolute text-[#28A9E2] right-3.5 top-1/2 translate-y-1/2 cursor-pointer"
                    icon={showPass ? "mdi:eye-off" : "mdi:eye"}
                    onClick={() => setShowPass((prev) => !prev)}
                  />
                </div>
                <button
                  className="w-full my-5 max-lg:py-2.5 p-4 bg-[#0168B5] text-white font-semibold text-lg rounded-md text-center"
                  type="submit"
                >
                  Sign In
                </button>
              </form>
              <p
                className="text-center cursor-pointer w-full pt-4 lg:pt-10 px-4 font-medium text-[#096DB6]"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot your password?
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
