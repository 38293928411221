import { Persona } from "../pages/TrainingPersonasListing";

import BlackIcon from "../assets/Avatar-symbol-black.png";
import BlueIcon from "../assets/Avatar-symbol-blue.png";
import SelectComp, { OptionType } from "./SelectComp";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../services/states/store";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { transport } from "../services/Network";
import actions from "../services/states/actions";

interface Props {
  persona: Persona;
  index: number;
  focused: string;
  setFocused: () => void;
  personaSetting:
    | {
        persona_id: string;
        language: string;
        accent: string;
        gender: string;
      }
    | undefined;
}
const PersonaCard = ({
  persona,
  index,
  focused,
  setFocused,
  personaSetting,
}: Props) => {
  const dispatch = useAppDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<
    OptionType | undefined
  >();
  const [selectedGender, setSelectedGender] = useState<OptionType | undefined>(
    personaSetting?.gender
      ? {
          value: personaSetting?.gender,
          label: personaSetting?.gender,
        }
      : undefined
  );
  const [accents, setAccents] = useState<OptionType[]>([]);
  const [selectedAccent, setSelectedAccent] = useState<OptionType | undefined>(
    personaSetting?.accent
      ? {
          value: personaSetting?.accent,
          label:
            personaSetting?.accent + personaSetting?.gender
              ? "-" + personaSetting?.gender
              : "",
        }
      : undefined
  );
  const languagesVoices = useSelector(
    (state: RootState) => state.languagesVoices
  );
  const languages = useSelector((state: RootState) => state.languages);
  useEffect(() => {
    if (languages?.length > 0) {
      setSelectedLanguage(
        languages.find(
          (val: OptionType) => val.value === personaSetting?.language
        )
      );
    }
  }, [languages, personaSetting?.language]);
  useEffect(() => {
    if (selectedLanguage && languagesVoices) {
      console.log(selectedLanguage, languagesVoices);
      setAccents(
        languagesVoices
          .filter((voice: any) =>
            voice.voice_lang.includes(selectedLanguage.value + "-")
          )
          .map((voice: any) => {
            if (voice.voice_name === personaSetting?.accent) {
              setSelectedAccent({
                value: voice.voice_name,
                label: voice.voice_name + "-" + voice.voice_gender,
              });
            }
            return {
              value: voice.voice_name,
              label: voice.voice_name + "-" + voice.voice_gender,
            };
          })
      );
    } else {
      setAccents([]);
      setSelectedAccent(undefined);
    }
  }, [selectedLanguage, languagesVoices]);
  useEffect(() => {
    if (selectedAccent && languagesVoices) {
      const voice = languagesVoices.find(
        (voice: any) => voice.voice_name === selectedAccent.value
      );
      if (voice) {
        setSelectedGender({
          value: voice.voice_gender,
          label: voice.voice_gender,
        });
      } else {
        setSelectedGender(undefined);
      }
    } else {
      setSelectedGender(undefined);
    }
  }, [languagesVoices, selectedAccent]);
  console.log(selectedAccent);
  return (
    <div
      onMouseOver={() => setFocused()}
      key={"assesment_card_" + index}
      className={`flex items-center gap-4 bg-white rounded p-5 pb-3 cursor-pointer border-l-8 ${
        focused === persona.persona_id
          ? "border-bgprimary"
          : "border-transparent"
      }`}
    >
      <img
        className="object-center w-10 mt-1 self-start"
        src={focused == persona.persona_id ? BlueIcon : BlackIcon}
        alt=""
      />
      <div className="flex-1">
        <h3 className="font-bold md:text-lg text-gray-800">
          Persona: {persona.persona_id}
        </h3>
        <p className="text-sm w-full max-w-[60rem] text-gray-500 line-clamp-2">
          {persona.persona_desc
            ? persona.persona_desc
            : "No description found."}
        </p>
        <div className="grid gap-10 pt-4 grid-cols-3">
          <SelectComp
            label=""
            options={languages}
            selectedValues={selectedLanguage ? [selectedLanguage] : []}
            setSelectedValues={(values: OptionType[]) => {
              setSelectedLanguage(
                values.length === 0 ? undefined : values.at(0)
              );
            }}
            isSingle
            placeholder="Language"
            order={false}
          />
          <SelectComp
            label=""
            options={accents}
            selectedValues={selectedAccent ? [selectedAccent] : []}
            setSelectedValues={(values: OptionType[]) => {
              setSelectedAccent(values.length === 0 ? undefined : values.at(0));
            }}
            isSingle
            placeholder="Accent"
            order={false}
          />
          <div>
            <button
              onClick={async () => {
                if (selectedAccent && selectedGender && selectedLanguage) {
                  const resp = await transport("/trainer/persona", {
                    data: {
                      persona_id: persona.persona_id,
                      accent: selectedAccent.value,
                      language: selectedLanguage.value,
                      gender: selectedGender.value,
                    },
                    method: "PUT",
                  });
                  if (resp.status === 200) {
                    toast.success(resp.data.message);
                    dispatch(actions.getPersonas());
                  } else {
                    toast.error(
                      resp?.data?.message ??
                        "Persona update failed. Please try again later."
                    );
                  }
                } else {
                  toast.error("Please lanugage, accent and gender to update");
                }
              }}
              disabled={
                !selectedAccent ||
                (personaSetting?.accent === selectedAccent?.value &&
                  personaSetting?.gender === selectedGender?.value &&
                  personaSetting?.language === selectedLanguage?.value)
              }
              className="bg-primary disabled:opacity-50 px-8 py-1.5 max-w-fit self-center rounded-md text-white font-bold"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonaCard;
