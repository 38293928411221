import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import TableTopBar from "../components/TableTopBar";
import PersonaIcon from "../assets/persona.png";
// import QuestionIcon from "../assets/question.png";
// import ReputationIcon from "../assets/reputation.png";
// import ComplaintIcon from "../assets/complaint.png";
// import PasswordLockIcon from "../assets/passwordpersona.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../services/states/store";
import actions from "../services/states/actions";
import PersonaCard from "../components/PersonaCard";

export interface Persona {
  persona_id: string;
  persona_desc: string;
}

const TrainingPersonasListing = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [focused, setFocused] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const personasData: Persona[] = useSelector(
    (state: RootState) => state.personas.personas as Persona[]
  );
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const personasSettings: any[] = useSelector(
    (state: RootState) => state.personas.settings
  );
  const selectedGroup = useSelector((state: RootState) => state.selectedGroup);
  const languages = useSelector((state: RootState) => state.languages);
  const languagesVoices = useSelector(
    (state: RootState) => state.languagesVoices
  );
  const dispatch: AppDispatch = useDispatch();
  const [filteredPersonasData, setFilteredPersonasData] = useState<Persona[]>(
    []
  );
  useEffect(() => {
    if (personasData?.length === 0) {
      dispatch(actions.getPersonas());
    }
    if (languages?.length === 0) {
      dispatch(actions.getLanguages());
    }
    if (languagesVoices?.length === 0) {
      dispatch(actions.getLanguagesVoices());
    }
  }, []);
  useEffect(() => {
    const groupSelected = availableGroups.find(
      (grp: any) => grp.id == selectedGroup
    );
    let personasTemp: any[] = [];
    if (personasData && groupSelected && groupSelected.personas.length > 0) {
      personasTemp = personasData.filter((persona) =>
        groupSelected.personas.includes(persona.persona_id)
      );
    } else if (!groupSelected) {
      const personas: string[] = [].concat(
        ...availableGroups
          .filter((group: any) => group?.personas && group.status === "active")
          .map((group: any) => group?.personas)
      );
      if (personasData) {
        personasTemp = personasData?.filter((persona) =>
          personas.includes(persona.persona_id)
        );
      }
    }

    if (searchInput?.length > 0) {
      personasTemp = personasTemp.filter(
        (persona: Persona) =>
          persona.persona_id
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          persona.persona_desc.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    setFilteredPersonasData(personasTemp);
  }, [searchInput, personasData, selectedGroup, availableGroups]);

  return (
    <Layout>
      <div className="flex w-full pb-2 items-center gap-4">
        <img src={PersonaIcon} alt="persona" className="max-md:h-10" />
        <div className="flex-1">
          <div className="text-3xl md:text-4xl">Personas</div>
          <p className="line-clamp-2 max-md:text-sm">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque
            nihil recusandae ducimus quo.
          </p>
        </div>
      </div>
      <TableTopBar
        setAction={() => {}}
        pageName="Persona"
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        updateSearchValue={setSearchInput}
        showAction={false}
        showViewAll={false}
        type={1}
        totalCount={Math.ceil(
          filteredPersonasData ? filteredPersonasData.length / 8 : 0
        )}
        showSelectGroup={true}
      />
      <div className="flex flex-col items-stretch gap-y-4 py-6">
        {filteredPersonasData?.length > 0 ? (
          filteredPersonasData
            ?.slice(currentPage * 8, currentPage * 8 + 8)
            .map((persona, index) => (
              <PersonaCard
                key={"persona_index" + index}
                persona={persona}
                personaSetting={personasSettings.find(
                  (val) => val.persona_id === persona.persona_id
                )}
                index={index}
                focused={focused}
                setFocused={() => setFocused(persona.persona_id)}
              />
            ))
        ) : (
          <div className="text-center text-gray-500 italic p-8">
            No persona found
            {searchInput.length > 0 ? " with given searched value" : ""}
          </div>
        )}
      </div>
      <TableTopBar
        setAction={() => {}}
        currentPage={currentPage}
        pageName="Persona"
        setCurrentPage={setCurrentPage}
        showAction={false}
        showViewAll={false}
        totalCount={Math.ceil(
          filteredPersonasData ? filteredPersonasData.length / 8 : 0
        )}
        type={2}
      />
    </Layout>
  );
};

export default TrainingPersonasListing;
