import { Icon } from "@iconify/react";
import closeImg from "../../assets/close.png";
import userIcon from "../../assets/usericon.png";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../services/states/store";
import actions from "../../services/states/actions";
import toast from "react-hot-toast";
import { transport } from "../../services/Network";
import SelectComp from "../SelectComp";

const EditGroupModal = (props: { id: string; close: () => void }) => {
  const [selectedTrainee, setSelectedTrainee] = useState<any[]>([]);
  const [selectedPersonas, setSelectedPersonas] = useState<any[]>([]);
  const [active, setActive] = useState(true);
  const [groupName, setGroupName] = useState("");
  const availableTrainees: any[] = useSelector(
    (state: RootState) => state.users
  );
  const availablePersonas: any[] = useSelector(
    (state: RootState) => state.personas.personas
  );
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (availableTrainees.length === 0) {
      dispatch(actions.getUsers());
    }
    if (availablePersonas.length === 0) {
      dispatch(actions.getPersonas());
    }
    if (availableGroups.length === 0) {
      dispatch(actions.getGroups());
    }
  }, []);
  const onClose = () => {
    setSelectedTrainee([]);
    setGroupName("");
    setSelectedPersonas([]);
    props.close();
  };
  useEffect(() => {
    if (availableGroups.length > 0) {
      const group = availableGroups.find((val) => val.id === props.id);
      if (group) {
        setGroupName(group.name);
        setActive(group.status === "active");
        setSelectedTrainee(
          group.trainees.map((val: any) => {
            return {
              label: val.fullname,
              value: val.id,
            };
          })
        );
        setSelectedPersonas(
          group.personas.map((val: any) => {
            return {
              label: val,
              value: val,
            };
          })
        );
      }
    }
  }, [availableGroups, props.id]);

  return props.id ? (
    <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center">
      <div className="p-0 w-[32rem] bg-white border border-black rounded">
        <div className="flex bg-bgprimary py-2.5 pl-8 pr-5 border-b-[5px] border-primary text-white items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src={userIcon} alt="usericon" />
            <h3 className="text-2xl">Edit Group</h3>
          </div>
          <img
            src={closeImg}
            className="cursor-pointer"
            onClick={props.close}
            alt="close"
          />
        </div>
        <div className="w-full flex flex-col gap-4 items-stretch bg-white px-8 py-6">
          <div>
            <label className="block pb-1 pl-0.5 text-gray-600" htmlFor="title">
              Group Title
            </label>
            <input
              type="text"
              placeholder="Title"
              name="title"
              id="title"
              onChange={(ev) => setGroupName(ev.target.value)}
              value={groupName}
              className="bg-bgsecondary w-full border border-gray-400 placeholder:text-gray-400 text-gray-600 rounded px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            />
          </div>
          <SelectComp
            label="Add to Group"
            options={availableTrainees.map((val) => {
              return {
                label: val.fullname,
                value: val.id,
              };
            })}
            selectedValues={selectedTrainee}
            placeholder="Select Trainee to add"
            setSelectedValues={setSelectedTrainee}
            order={true}
          />
          <SelectComp
            label="Assign Personas"
            options={availablePersonas.map((val) => {
              return {
                label: val.persona_id,
                value: val.persona_id,
              };
            })}
            selectedValues={selectedPersonas}
            placeholder="Select persona to add"
            setSelectedValues={setSelectedPersonas}
            order={true}
          />
          <div className="w-full flex gap-4">
            <b>Status: </b>
            <div className="flex">
              <div
                onClick={() => setActive(true)}
                className={`w-4 cursor-pointer h-4 p-0.5 mr-1 flex items-center justify-center md:mr-4 mt-1 rounded-md border-2 ${
                  active ? "border-[#E6841F] text-[#E6841F]" : "border-gray-500"
                } `}
              >
                {active ? <Icon icon="streamline:check-solid" /> : null}
              </div>
              Active
            </div>
            <div className="flex">
              <div
                onClick={() => setActive(false)}
                className={`w-4 cursor-pointer h-4 p-0.5 mr-1 flex items-center justify-center md:mr-4 mt-1 rounded-md border-2 ${
                  !active
                    ? "border-[#E6841F] text-[#E6841F]"
                    : "border-gray-500"
                } `}
              >
                {!active ? <Icon icon="streamline:check-solid" /> : null}
              </div>
              Completed
            </div>
          </div>
          <div className="h-2"></div>
          <div className="flex justify-between items-center">
            <button
              onClick={async () => {
                if (groupName) {
                  if (groupName.trim().length <= 1) {
                    toast.error("Group title must be greater than 1 character");
                    return;
                  }
                  // "/group/"
                  const res = await transport("/group/", {
                    method: "PUT",
                    data: {
                      id: props.id,
                      name: groupName,
                      trainees: selectedTrainee.reduce((acc, current) => {
                        acc.push(current.value);
                        return acc;
                      }, []),
                      assignments: undefined,
                      status: active ? "active" : "completed",
                      personas: selectedPersonas.reduce((acc, current) => {
                        acc.push(current.value);
                        return acc;
                      }, []),
                    },
                  });
                  if (res?.status === 200) {
                    dispatch(actions.getGroups());
                    dispatch(actions.getUsers());
                    toast.success(res.data.message);
                    setTimeout(() => {
                      onClose();
                    }, 300);
                  } else {
                    toast.error(res.data.message);
                  }
                } else {
                  toast.error("Group title is required");
                }
              }}
              className="text-white flex-1 max-w-40 bg-primary rounded py-2.5 px-5 text-base"
            >
              Save Group
            </button>
            <span className="text-xl text-gray-500">or</span>
            <button
              onClick={onClose}
              className="bg-gray-200 flex-1 max-w-40 text-gray-600 rounded py-2.5 px-5 text-base"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default EditGroupModal;
