import DataCard from "./DataCard";
import userIcon from "../assets/user.png";
import { RootState, useAppDispatch } from "../services/states/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import actions from "../services/states/actions";
import Table from "./Table";
import { Link } from "react-router-dom";
import { Persona } from "../pages/TrainingPersonasListing";
import { Icon } from "@iconify/react";
import { transport } from "../services/Network";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  Tooltip,
  Colors,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
} from "chart.js";
import moment from "moment";
Chart.register(Tooltip, Colors, CategoryScale, LinearScale, BarElement, Legend);
export type DataCardDataype = {
  title: string;
  text: string;
};
const TrainerDashboard = () => {
  const users: [] = useSelector((state: RootState) => state.users);
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const personasAvailable: Persona[] = useSelector(
    (state: RootState) => state.personas.personas as Persona[]
  );
  const availableGroups: any[] = useSelector(
    (state: RootState) => state.groups
  );
  const [personasData, setPersonasData] = useState<Persona[]>([]);
  const selectedGroup = useSelector((state: RootState) => state.selectedGroup);
  const dispatch = useAppDispatch();
  const [dataLeaderBoard, setDataLeaderBoard] = useState<any[]>([]);
  const analyticsData = useSelector((state: RootState) => state.analyticsData);
  useEffect(() => {
    if (!users || users.length === 0) {
      dispatch(actions.getUsers());
    }
    if (personasData?.length === 0) {
      dispatch(actions.getPersonas());
    }
    if (availableGroups.length === 0) {
      dispatch(actions.getGroups());
    }
  }, []);
  useEffect(() => {
    if (
      selectedGroup &&
      availableGroups.findIndex(
        (grp) => grp.id == selectedGroup && grp.status !== "completed"
      ) > -1
    ) {
      transport("/scores/latest_scores?group_id=" + selectedGroup).then(
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            const dataLeaderBoardTemp = [
              ...res.data.data.sort((a: any, b: any) => {
                const val = b.score - a.score;
                return val === 0
                  ? a.trainee_fullname.localeCompare(b.trainee_fullname)
                  : val;
              }),
            ];
            setDataLeaderBoard(
              dataLeaderBoardTemp.reduce((acc: any[], val: any) => {
                if (acc.length == 0) {
                  acc.push({
                    trainee_id: val.trainee_id,
                    name: val.trainee_fullname,
                    points: val.score,
                    rank: 1,
                  });
                } else {
                  if (acc.at(-1).points > val.score) {
                    acc.push({
                      trainee_id: val.trainee_id,
                      name: val.trainee_fullname,
                      points: val.score,
                      rank: acc.at(-1).rank + 1,
                    });
                  } else {
                    acc.push({
                      trainee_id: val.trainee_id,
                      name: val.trainee_fullname,
                      points: val.score,
                      rank: acc.at(-1).rank,
                    });
                  }
                }
                return acc;
              }, [])
            );
          } else {
            setDataLeaderBoard([]);
          }
        }
      );
    } else {
      setDataLeaderBoard([]);
    }
    if (selectedGroup) {
      dispatch(actions.getAnalyticsData("trainer", selectedGroup, false));
    } else {
      dispatch(actions.getAnalyticsData("trainer", selectedGroup, true));
    }
  }, [selectedGroup]);
  useEffect(() => {
    if (personasAvailable && availableGroups) {
      const personas: string[] = [].concat(
        ...availableGroups
          .filter((group: any) => group?.personas && group.status === "active")
          .map((group: any) => group?.personas)
      );
      setPersonasData(
        personasAvailable.filter((persona) =>
          personas.includes(persona.persona_id)
        )
      );
    }
  }, [availableGroups, personasAvailable]);

  return (
    <div className="max-w-6xl">
      <div className="flex items-center justify-between">
        <div className="w-fit pt-3 pb-5">
          <div className="relative max-md:w-full">
            <select
              name="groups"
              id="groups"
              value={selectedGroup}
              onChange={(ev) => {
                dispatch(actions.selectGroup(ev.target.value));
              }}
              className="bg-white h-10 w-full md:w-60 pr-8 appearance-none border-2 border-gray-400 text-gray-400 rounded-md px-3 py-1.5 focus:outline-none focus:ring-0 text-sm"
            >
              <option defaultChecked value="">
                Select Group
              </option>
              {userDetails?.groups?.length > 0 &&
                userDetails?.groups
                  ?.filter((grp: any) => grp.status === "active")
                  .sort((a: any, b: any) =>
                    a?.name?.toString().localeCompare(b?.name?.toString(), {
                      numeric: true,
                    })
                  )
                  .map((group: any, index: number) => (
                    <option
                      key={"Select-option-group-" + index}
                      value={group.id}
                    >
                      {group.name}
                    </option>
                  ))}
            </select>
            <Icon
              className="absolute right-2 text-gray-700 top-1/2 text-xl pointer-events-none -translate-y-1/2 z-50"
              icon="tabler:caret-down-filled"
            />
          </div>
        </div>
        <div>
          {analyticsData?.start_date ? <b>Start date: </b> : ""}
          {analyticsData?.start_date
            ? moment
                .utc(analyticsData?.start_date)
                .local()
                .format("Do MMMM YYYY, h:mma")
            : ""}
        </div>
      </div>

      {selectedGroup ? (
        <>
          <div className="flex gap-8 xl:justify-between flex-wrap">
            {[
              {
                title: `${
                  availableGroups.reduce((acc, currGrp) => {
                    if (
                      currGrp?.status === "active" &&
                      currGrp?.trainees?.length > 0 &&
                      currGrp.id == selectedGroup
                    ) {
                      acc = [
                        ...acc,
                        ...currGrp?.trainees?.map((trainee: any) => trainee.id),
                      ];
                    }
                    return acc;
                  }, []).length ?? 0
                }`,
                text: "Active Trainees",
              },
              {
                title: `${
                  analyticsData?.completed_trainees?.filter(
                    (trainee: any) => trainee
                  ).length ?? 0
                }`,
                text: "Completed Trainees",
              },
              {
                title: `${(analyticsData?.avg_daily ?? 0).toFixed(2)}`,
                text: "Avg Daily Conversations",
              },
              {
                title: `${(
                  (analyticsData?.failed_today
                    ? analyticsData?.failed_today /
                      (analyticsData?.total_today
                        ? analyticsData?.total_today
                        : 1)
                    : 0) * 100
                ).toFixed(2)}%`,
                text: "Average Failure Rate",
              },
            ].map((val, index) => (
              <DataCard key={"dashboard_data_card" + index} {...val} />
            ))}
          </div>
          {analyticsData?.analysis || analyticsData?.persona_analysis ? (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 py-10 ">
              <div className="h-full mx-auto w-full relative">
                {Object.keys(analyticsData.persona_analysis).length > 0 &&
                Object.keys(analyticsData.persona_analysis).find(
                  (persona) =>
                    analyticsData.persona_analysis[persona].failed > 0 ||
                    analyticsData.persona_analysis[persona].passed > 0
                ) ? (
                  <Bar
                    options={{
                      responsive: true,
                    }}
                    data={{
                      labels: analyticsData?.persona_analysis
                        ? Object.keys(analyticsData?.persona_analysis)
                        : ["unknown"],
                      datasets: [
                        {
                          label: "Passed",
                          backgroundColor: "#059bff",
                          data: analyticsData?.persona_analysis
                            ? Object.keys(analyticsData?.persona_analysis).map(
                                (persona) =>
                                  analyticsData.persona_analysis[persona].passed
                              )
                            : [0],
                        },
                        {
                          label: "Failed",
                          backgroundColor: "#ff0000",
                          data: analyticsData?.persona_analysis
                            ? Object.keys(analyticsData?.persona_analysis).map(
                                (persona) =>
                                  analyticsData.persona_analysis[persona].failed
                              )
                            : [0],
                        },
                      ],
                    }}
                  />
                ) : (
                  <div className="text-center text-gray-500 italic p-8 pt-16">
                    No analysis found for persona assesments
                  </div>
                )}
              </div>
              <div className="h-full mx-auto w-full relative">
                {Object.keys(analyticsData.analysis).length > 0 ? (
                  <Bar
                    options={{
                      responsive: true,
                      indexAxis: "y",
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: analyticsData?.analysis
                        ? Object.keys(analyticsData?.analysis).sort((a, b) => {
                            const val =
                              analyticsData?.analysis[b] -
                              analyticsData?.analysis[a];

                            return val !== 0 ? val : a.localeCompare(b);
                          })
                        : ["unknown"],
                      datasets: [
                        {
                          backgroundColor: "#059bff",
                          data: analyticsData?.analysis
                            ? Object.keys(analyticsData?.analysis)
                                .sort((a, b) => {
                                  const val =
                                    analyticsData?.analysis[b] -
                                    analyticsData?.analysis[a];

                                  return val !== 0 ? val : a.localeCompare(b);
                                })
                                .map((assess) => analyticsData.analysis[assess])
                            : [0],
                        },
                      ],
                    }}
                  />
                ) : (
                  <div className="text-center text-gray-500 italic p-8 pt-16">
                    No analysis found for persona assesments
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500 italic p-8 pt-16">
              No analysis found
            </div>
          )}
          <div className="pb-10">
            <div className="flex w-full pb-2 items-center gap-4">
              <img src={userIcon} alt="user" className="max-md:h-10" />
              <span className="text-3xl lg:text-4xl">Leaderboard Top 5</span>
              <div className="flex-1"></div>
              <Link
                to={"/leaderboard"}
                className="bg-[#E6841F] px-3 py-1 md:py-2 rounded-md text-white"
              >
                View all
              </Link>
            </div>
            {dataLeaderBoard.length > 0 ? (
              <Table
                headers={["Name", "Points", "Rank"]}
                headerCodes={["name", "points", "rank"]}
                data={dataLeaderBoard
                  .slice(0, 5)
                  .map((val, idx) => [
                    idx,
                    val["name"],
                    val["points"] ?? 0,
                    val["rank"],
                  ])}
                checkedItems={[]}
                updateChecked={undefined}
                disableSorting={true}
                type="leaderboard"
              />
            ) : (
              <div className="text-center text-gray-500 italic p-8">
                No stats found
                {/* {searchInput.length > 0 ? " with given searched value" : ""} */}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="text-center text-gray-500 italic p-8 h-full">
          Please select a group to see analytics
        </div>
      )}
    </div>
  );
};

export default TrainerDashboard;
