import { transport } from "../Network";
import { AppDispatch } from "./store";

const actions = {
  getUserDetails: (role: string): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_USER",
        payload: await transport({
          url: role === "trainer" ? "/trainer/" : "/trainee/",
          method: "GET",
        }),
      });
    };
  },
  updateUserDetails: (
    role: string,
    name: string,
    pic: File | undefined
  ): any => {
    const data = new FormData();
    data.append("fullname", name);
    if (pic) {
      data.append("file", pic);
    }
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "UPDATE_USER",
        payload: await transport({
          url: role === "trainer" ? "/trainer/" : "/trainee/self",
          method: "PUT",
          data: data,
        }),
      });
    };
  },
  getUsers: (): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_USERS",
        payload: await transport({
          url: "/trainer/get_all_trainees",
        }),
      });
    };
  },
  getGroups: (): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_GROUPS",
        payload: await transport({
          url: "/group/get_all",
        }),
      });
    };
  },
  getPersonas: (): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_PERSONAS",
        payload: await transport("trainer/persona", {
          method: "GET",
        }),
      });
    };
  },
  getAnalyticsData: (role: string, group_id: string, clear: boolean): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_ANALYTICS_DATA",
        payload: clear
          ? {}
          : await transport(`${role}/analytics?group_id=${group_id}`, {
              method: "GET",
            }),
      });
    };
  },
  getLanguages: (): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_LANGUAGES",
        payload: await transport("auth/swagger", {
          method: "POST",
          data: {
            urlval:
              process.env.REACT_APP_PERSON_MGMT_URL+"/language/codes/?display_language_code=en",
            method: "GET",
          },
        }),
      });
    };
  },
  getLanguagesVoices: (): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_LANGUAGES_VOICES",
        payload: await transport("auth/swagger", {
          method: "POST",
          data: {
            urlval:
              process.env.REACT_APP_PERSON_MGMT_URL+"/language/voices/",
            method: "GET",
          },
        }),
      });
    };
  },
  getAssessments: (persona_id: string): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "GET_ASSESSMENTS",
        payload: await transport("auth/swagger", {
          method: "POST",
          data: {
            urlval:
              process.env.REACT_APP_PERSON_MGMT_URL+"/persona/get_assessments/" +
              persona_id,
            method: "GET",
          },
        }),
      });
    };
  },
  updateSelectedOption: (selectedOption: string): any => {
    return async (dispatch: AppDispatch) => {
      return dispatch({
        type: "UPDATE_SELECTED",
        payload: selectedOption,
      });
    };
  },
  sideBarToggle: (toggle: boolean): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_SIDEBAR",
        payload: toggle,
      });
    };
  },
  editTraineeToggle: (toggle: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_EDIT_TRAINEE",
        payload: toggle,
      });
    };
  },
  editGroupToggle: (toggle: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_EDIT_GROUP",
        payload: toggle,
      });
    };
  },
  viewTraineeToggle: (toggle: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_VIEW_TRAINEE",
        payload: toggle,
      });
    };
  },
  viewGroupToggle: (toggle: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_VIEW_GROUP",
        payload: toggle,
      });
    };
  },
  deleteTraineeToggle: (toggle: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_DELETE_TRAINEE",
        payload: toggle,
      });
    };
  },
  deleteGroupToggle: (toggle: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_DELETE_GROUP",
        payload: toggle,
      });
    };
  },
  sortTableData: (option: any[]): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "SORT_TABLE_DATA",
        payload: option,
      });
    };
  },
  selectPersona: (option: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "SELECT_PERSONA",
        payload: option,
      });
    };
  },
  selectGroup: (option: string): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "SELECT_GROUP",
        payload: option,
      });
    };
  },
  printingChange: (toggle: number): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "CHANGE_PRINTING",
        payload: toggle,
      });
    };
  },
  toggleBotSpeech: (toggle: boolean): any => {
    return (dispatch: AppDispatch) => {
      return dispatch({
        type: "TOGGLE_BOT_SPEECH",
        payload: toggle,
      });
    };
  },
};
export default actions;
