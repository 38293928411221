import React from "react";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";

const Help = () => {
  return (
    <Layout showSidebar={false}>
      <div className="w-full min-h-[calc(100vh-9.5rem)] text-gray-700 flex flex-col gap-5 items-stretch bg-white p-4 md:p-8 rounded-md">
        <h1 className="text-3xl md:text-5xl text-black">Avatar Help</h1>
        <div className="text-lg">
          For technical assistance, please contact DataH2O Avatar customer
          support at{" "}
          <Link className="text-blue-600" to="mailto:support@datah2o.com">
            support@datah2o.com
          </Link>
        </div>
        <div className="text-lg">
          For urgent inquiries, you may leave a message at{" "}
          <Link className="text-black" to="tel:+447471138156">
            +44 7471138 156
          </Link>
          .
        </div>
        <div className="text-lg">
          All inquiries will be responded to before the end of the next
          bussiness day.
        </div>
      </div>
    </Layout>
  );
};

export default Help;
