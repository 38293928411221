import OverviewIcon from "../assets/listsearch.png";
import TrainingIcon from "../assets/training.png";
import LeaderboardIcon from "../assets/leaderboard.png";
import ReportsIcon from "../assets/reports.png";
import FeedbackIcon from "../assets/feedback.png";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../services/states/actions";
import { RootState } from "../services/states/store";
import Logo from "../assets/logo_with_text.png";
import { Icon } from "@iconify/react";
const SidebarElement = (props: {
  icon: string;
  text: string;
  active: boolean;
  updateActive: () => void;
}) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const options =
    localStorage.getItem("role") === "trainer"
      ? ["groups", "personas", "assessments", "users"]
      : ["sessions", "conversations"];
  return (
    <>
      <Link
        to={
          props.text === "reports"
            ? localStorage.getItem("role") === "trainer"
              ? "https://lookerstudio.google.com/u/0/reporting/3cc27273-86ee-4a15-8931-168e263a6ac6/page/p_xbl2aj2tfd"
              : `https://lookerstudio.google.com/u/0/reporting/3cc27273-86ee-4a15-8931-168e263a6ac6/page/p_xbl2aj2tfd?params=${encodeURIComponent(
                  JSON.stringify({ "ds6.email": userDetails?.email ?? "" })
                )}`
            : window.location.pathname.includes(props.text)
            ? window.location.pathname
            : "/" +
              props.text +
              (props.text === "training" ? "/" + options[0] : "")
        }
        target={props.text === "reports" ? "_blank" : undefined}
        onClick={() => {
          if (props.text !== "reports") {
            props.updateActive();
          }
        }}
        className={`flex relative items-center cursor-pointer gap-4 py-3 px-6 md:px-10 font-semibold border-l-8 ${
          props.active
            ? "border-bgprimary bg-[#0168B5] text-white"
            : "border-transparent text-gray-600"
        }`}
      >
        <img
          className={props.active ? "invert" : ""}
          src={props.icon}
          alt={props.text}
        />
        <div className="capitalize">{props.text}</div>
        {props.active && (
          <div className="absolute -right-0.5 top-[50%] max-md:hidden -translate-y-1/2 translate-x-1/2 rotate-45 bg-bgsecondary min-w-5 min-h-5 max-h-5"></div>
        )}
      </Link>
      {props.active && props.text === "training" && (
        <div className="px-12 py-2 bg-bgsecondary">
          {options.map((item, index) => (
            <Link
              key={"sidebar-item-elem-" + index}
              title={
                item === "conversations"
                  ? "Select persona from sessions first"
                  : ""
              }
              to={"/training/" + item}
              className={`flex items-center gap-2 py-2`}
              onClick={(ev) => {
                if (item === "conversations") {
                  ev.preventDefault();
                }

                dispatch(actions.sideBarToggle(false));
              }}
            >
              <span
                className={`capitalize ${
                  window.location.pathname.includes("/training/" + item)
                    ? " font-semibold text-[#27A9E2]"
                    : "text-sm font-medium text-gray-600"
                }`}
              >
                {item}
              </span>
              {/* <span className=" w-4 h-4 rounded-full bg-[#F1A85D] text-xs font-medium flex justify-center items-center">
                3
              </span> */}
            </Link>
          ))}
        </div>
      )}
    </>
  );
};
const Sidebar = () => {
  const [assessmentCount, setAssessmentCount] = useState(0);
  const selectedOption: string = useSelector(
    (state: RootState) => state.selectedOption
  );
  const sidebarToggle: boolean = useSelector(
    (state: RootState) => state.sidebarToggle
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname.includes("/training")) {
      dispatch(actions.updateSelectedOption("training"));
    } else if (window.location.pathname.includes("/leaderboard")) {
      dispatch(actions.updateSelectedOption("leaderboard"));
    } else if (window.location.pathname.includes("/reports")) {
      dispatch(actions.updateSelectedOption("reports"));
    } else if (window.location.pathname.includes("/feedback")) {
      dispatch(actions.updateSelectedOption("feedback"));
    } else {
      dispatch(actions.updateSelectedOption("overview"));
    }
  }, []);

  const optionsDataTrainer = [
    {
      icon: OverviewIcon,
      text: "overview",
    },
    {
      icon: TrainingIcon,
      text: "training",
    },
    { icon: LeaderboardIcon, text: "leaderboard" },
    {
      icon: ReportsIcon,
      text: "reports",
    },
  ];
  const optionsDataTrainee = [
    {
      icon: OverviewIcon,
      text: "overview",
    },
    {
      icon: TrainingIcon,
      text: "training",
    },

    {
      icon: FeedbackIcon,
      text: "feedback",
    },
    {
      icon: ReportsIcon,
      text: "reports",
    },
  ];
  return (
    <div
      className={`h-screen md:h-[calc(100vh-80px)] w-64 max-w-64 min-h-full flex flex-col items-stretch bg-white shadow-cardshadow ${
        sidebarToggle ? "max-md:fixed top-0 z-50 left-0" : "max-md:hidden"
      }`}
    >
      <div className="w-full flex p-2 items-center justify-between md:hidden bg-bgprimary">
        <img
          onClick={() => navigate("/")}
          className="cursor-pointer"
          src={Logo}
          alt="logo"
        />
        <Icon
          onClick={() => {
            dispatch(actions.sideBarToggle(false));
          }}
          icon="ep:close-bold"
          className="text-white cursor-pointer text-2xl"
        />
      </div>
      {(localStorage.getItem("role") === "trainer"
        ? optionsDataTrainer
        : optionsDataTrainee
      ).map((optionData, index) => (
        <SidebarElement
          key={"Sidebar-item-" + index}
          active={selectedOption === optionData.text}
          updateActive={() => {
            dispatch(actions.updateSelectedOption(optionData.text));
            if (optionData.text !== "training") {
              dispatch(actions.sideBarToggle(false));
            }
          }}
          icon={optionData.icon}
          text={optionData.text}
        />
      ))}
      <div className="flex-1"></div>
      <button
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
        className="bg-primary px-4 py-2 max-w-fit self-center mb-4 rounded-md text-white font-bold"
      >
        Log out
      </button>
    </div>
  );
};

export default Sidebar;
