import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import userIcon from "../assets/user.png";
import TableTopBar from "../components/TableTopBar";
import Table from "../components/Table";
import AddTraineeModal from "../components/Modals/AddTraineeModal";
import actions from "../services/states/actions";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../services/states/store";
import EditTraineeModal from "../components/Modals/EditTraineeModal";
import DeleteModal from "../components/Modals/DeleteModal";
import { transport } from "../services/Network";
import toast from "react-hot-toast";
import ViewTraineeModal, {
  getTimeAgo,
} from "../components/Modals/ViewTraineeModal";

const TrainingUsersListing = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [action, setAction] = useState("");
  const [showAddTrainee, setShowAddTrainee] = useState(false);
  const [selected, setSelected] = useState<any[]>([]);
  const users: [] = useSelector((state: RootState) => state.users);
  const selectedOption: any[] = useSelector(
    (state: RootState) => state.sortTableData
  );
  const showEdit: string = useSelector(
    (state: RootState) => state.editTraineeToggle
  );
  const showDelete: string = useSelector(
    (state: RootState) => state.deleteTraineeToggle
  );
  const showView: string = useSelector(
    (state: RootState) => state.viewTraineeToggle
  );
  const [filteredUsers, setFilteredUsers] = useState(users as any[]);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!users || users.length === 0) {
      dispatch(actions.getUsers());
    }
  }, []);
  useEffect(() => {
    if (searchInput?.length > 0) {
      setFilteredUsers(
        users.filter(
          (user: any) =>
            user.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.fullname.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    } else {
      setFilteredUsers([
        ...[
          ...users
            .filter((val: any) => val.id)
            .sort((a: any, b: any) =>
              selectedOption[2] === "desc"
                ? `${
                    b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    b["fullname"]
                  }`.localeCompare(
                    `${
                      a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                      a["fullname"]
                    }`
                  )
                : `${
                    a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    a["fullname"]
                  }`?.localeCompare(
                    `${
                      b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                      b["fullname"]
                    }`
                  )
            ),
        ],
        ...[
          ...users
            .filter((val: any) => !val.id)
            .sort((a: any, b: any) =>
              selectedOption[2] === "desc"
                ? `${
                    b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    b["fullname"]
                  }`.localeCompare(
                    `${
                      a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                      a["fullname"]
                    }`
                  )
                : `${
                    a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    a["fullname"]
                  }`?.localeCompare(
                    `${
                      b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                      b["fullname"]
                    }`
                  )
            ),
        ],
      ]);
    }
  }, [searchInput, users]);
  useEffect(() => {
    setFilteredUsers([
      ...[
        ...filteredUsers
          .filter((val: any) => val.id)
          .sort((a: any, b: any) =>
            selectedOption[2] === "desc"
              ? `${
                  b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                  b["fullname"]
                }`.localeCompare(
                  `${
                    a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    a["fullname"]
                  }`
                )
              : `${
                  a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                  a["fullname"]
                }`?.localeCompare(
                  `${
                    b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    b["fullname"]
                  }`
                )
          ),
      ],
      ...[
        ...filteredUsers
          .filter((val: any) => !val.id)
          .sort((a: any, b: any) =>
            selectedOption[2] === "desc"
              ? `${
                  b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                  b["fullname"]
                }`.localeCompare(
                  `${
                    a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    a["fullname"]
                  }`
                )
              : `${
                  a[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                  a["fullname"]
                }`?.localeCompare(
                  `${
                    b[selectedOption[1] ? selectedOption[1] : "fullname"] ??
                    b["fullname"]
                  }`
                )
          ),
      ],
    ]);
  }, [selectedOption]);
  return (
    <>
      <Layout>
        <div className="flex w-full pb-2 items-center gap-4">
          <img src={userIcon} alt="user" className="max-md:h-10" />
          <span className="text-3xl lg:text-4xl">
            Users ({users?.length ?? 0})
          </span>
          <button
            onClick={() => setShowAddTrainee(true)}
            className="bg-[#E6841F] px-3 py-1 md:py-2 rounded-md text-white"
          >
            Add a user
          </button>
        </div>
        <TableTopBar
          action={action}
          pageName="user"
          setAction={setAction}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          updateSearchValue={setSearchInput}
          total={filteredUsers.length}
          type={1}
          checkedItems={selected}
          totalCount={Math.ceil(filteredUsers ? filteredUsers.length / 8 : 0)}
        />
        {filteredUsers?.length > 0 ? (
          <Table
            headers={["Names", "Points", "Completed", "Group", "Last Activity"]}
            headerCodes={[
              "fullname",
              "score",
              "completed_personas",
              "groups",
              "last_login",
            ]}
            type="user"
            data={filteredUsers
              ?.slice(currentPage * 8, currentPage * 8 + 8)
              .map((user, index) => [
                user["id"] ?? user["email"],
                user["fullname"],
                user["id"] ? user["score"] : user["email"],
                user["completed_personas"] ? user["completed_personas"] : 0,
                (user["groups"] as [])?.length
                  ? (user["groups"] as [])
                      .map((val: any) => val.name)
                      .join(", ")
                  : "None",
                getTimeAgo(user["last_login"]),
              ])}
            checkedItems={selected}
            updateChecked={setSelected}
          />
        ) : (
          <div className="text-center text-gray-500 italic p-8">
            No user found
            {searchInput.length > 0 ? " with given searched value" : ""}
          </div>
        )}
        {filteredUsers?.length > 0 ? (
          <TableTopBar
            action={action}
            setAction={setAction}
            pageName="user"
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            type={2}
            checkedItems={selected}
            totalCount={Math.ceil(filteredUsers ? filteredUsers.length / 8 : 0)}
          />
        ) : (
          <></>
        )}
      </Layout>
      <AddTraineeModal
        show={showAddTrainee}
        close={() => setShowAddTrainee(false)}
      />
      <EditTraineeModal
        id={showEdit}
        close={() => dispatch(actions.editTraineeToggle(""))}
      />
      <ViewTraineeModal
        trainee={
          showView
            ? filteredUsers.find((val) => val.id === showView)
            : undefined
        }
        close={() => dispatch(actions.viewTraineeToggle(""))}
      />
      <DeleteModal
        id={showDelete}
        type="Trainee"
        close={() => dispatch(actions.deleteTraineeToggle(""))}
        onDelete={async () => {
          const res = await transport("/trainee/" + showDelete, {
            method: "DELETE",
          });
          if (res?.status === 200) {
            dispatch(actions.getGroups());
            dispatch(actions.getUsers());
            toast.success(res.data.message);
            setTimeout(() => {
              dispatch(actions.deleteTraineeToggle(""));
            }, 300);
          } else {
            toast.error(res.data.message);
          }
        }}
      />
    </>
  );
};

export default TrainingUsersListing;
